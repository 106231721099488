import Container from "components/Container";
import FeaturedProjects from "components/FeaturedProjects";
import Header from "components/Header";
import Layout from "components/Layout";
import Link from "components/Link";
import { graphql } from "gatsby";
import { map } from "lodash";
import { rgba } from "polished";
import React from "react";
import styled from "@emotion/styled";

const ProjectsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4rem;
`;

const Button = styled(Link)`
  background-color: hsla(0, 0%, 0%, 0.8);
  box-shadow: 0 10px 20px ${rgba("#c0c0c0", 0.5)};
  border-radius: 50rem;
  color: white;
  display: block;
  font-weight: bold;
  margin: 0 0.5rem 1rem;
  padding: 0.75rem 2rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.25s;

  &:hover {
    box-shadow: 0 15px 20px #c0c0c0;
    transform: translateY(-5px);
  }
`;

export default ({
  data: {
    featuredProjects: { edges: featuredProjectsEdges },
  },
}) => (
  <Layout>
    <Header title="Alexandre Breteau">Polyglot full-stack developer</Header>
    <Container>
      <FeaturedProjects projects={featuredProjectsEdges} />
      <ButtonGroup>
        <Button to="/projects">All projects</Button>
      </ButtonGroup>
    </Container>
  </Layout>
);

export const pageQuery = graphql`
  query {
    featuredProjects: allMarkdownRemark(
      filter: {
        fields: { sourceInstanceName: { eq: "projects" } }
        frontmatter: { featured: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM YYYY")
            title
            headline
          }
        }
      }
    }
  }
`;
