import ProjectCard from "components/ProjectCard";
import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export default ({ projects }) => (
  <Wrapper>
    {projects.map(project => (
      <ProjectCard
        key={project.node.fields.slug}
        path={project.node.fields.slug}
        title={project.node.frontmatter.title}
        headline={project.node.frontmatter.headline}
        date={project.node.frontmatter.date}
      />
    ))}
  </Wrapper>
);
